
import { Roles, Users } from "@/api";
import { Role, User } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: { ValidationProvider, ValidationObserver, PageTitle }
})
export default class CreateUserPage extends Vue {
  headers = [{ icon: "mdi-account-lock", header: "Create User" }];
  buttonsArray = [
    {
      text: "Back to list",
      icon: "",
      color: "gray",
      action: "back"
    }
  ];

  user: User = {
    name: "",
    email: "",
    roles: [],
    active: true
  };
  roles: Array<Role> = [];
  async created() {
    this.roles = await Roles.list();
  }
  async save() {
    try {
      await Users.create(this.user);
      await this.$success("<strong>Success!</strong> User has been created!");
      this.$router.push({ name: "Users" });
    } catch (e) {
      const err = e as any;
      (this.$refs.userForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(err.errors);
      await this.$error(err.errors);
    }
  }
}
